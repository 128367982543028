import React, { createContext, useState, useEffect, useContext } from 'react';

const MonthHook = createContext({});

function MonthProvider({ children }) {
  const [months] = useState(() => {
    return [
      { value: 1, label: 'Janeiro', short: 'Jan' },
      { value: 2, label: 'Fevereiro', short: 'Fev' },
      { value: 3, label: 'Março', short: 'Mar' },
      { value: 4, label: 'Abril', short: 'Abr' },
      { value: 5, label: 'Maio', short: 'Mai' },
      { value: 6, label: 'Junho', short: 'Jun' },
      { value: 7, label: 'Julho', short: 'Jul' },
      { value: 8, label: 'Agosto', short: 'Ago' },
      { value: 9, label: 'Setembro', short: 'Set' },
      { value: 10, label: 'Outubro', short: 'Out' },
      { value: 11, label: 'Novembro', short: 'Nov' },
      { value: 12, label: 'Dezembro', short: 'Dez' },
    ];
  });


  const [currentMonth] = useState(() => {
    const currMonth = new Date().getUTCMonth() + 1;
    return [...months.filter((month) => month.value === currMonth)][0];
  });

  const [cycle] = useState(() => {
    const currMonth = new Date().getUTCMonth() + 1;
    let currCycle = '';
    if ([1, 2, 3].includes(currMonth)) {
      currCycle = '1';
    }
    if ([4, 5, 6].includes(currMonth)) {
      currCycle = '2';
    }
    if ([7, 8, 9].includes(currMonth)) {
      currCycle = '3';
    }
    if ([10, 11, 12].includes(currMonth)) {
      currCycle = '4';
    }

    currCycle += '° Trimestre';

    return currCycle;
  });

  useEffect(() => {}, []);

  return (
    <MonthHook.Provider value={{ months, cycle, currentMonth }}>
      {children}
    </MonthHook.Provider>
  );
}

function useMonth() {
  const context = useContext(MonthHook);

  if (!context) {
    throw new Error('useMonth must be used within and MonthProvider');
  }

  return context;
}

export { MonthProvider, useMonth };
